import {FormEvent, useState} from "react";
import { useAuthState, useSignInWithEmailAndPassword } from "react-firebase-hooks/auth";
import {auth} from "../../api/firebase";
import {useNavigate} from "react-router-dom";
import {Alert, Form} from "react-bootstrap";

export default function Login() {
    const [email, setEmail] = useState("");
    const [pin, setPin] = useState(0);

    let [user, loading, error] = useAuthState(auth);
    const [signup, sUser, sLoading, sError] = useSignInWithEmailAndPassword(auth);

    user = user || sUser?.user;
    loading = loading || sLoading;
    error = error || sError;

    const navigate = useNavigate();

    function handleLogin(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();
        signup(email, pin.toString()).then();
    }

    if(user)
        navigate("/team");

    return (
        <div className="container mt-5 justify-content-center d-flex">
            <Form onSubmit={handleLogin} className=" p-5 shadow bg-body-tertiary">
                <Alert variant="danger" hidden={!error}>{error?.message}</Alert>
                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor=".">Email address</label>
                    <input type="email" className="form-control" required
                        onChange={(e) => setEmail(e.target.value)}/>
                </div>

                <div className="form-outline mb-4">
                    <label className="form-label" htmlFor=".">PIN</label>
                    <input type="password" className="form-control" pattern="\d{6}" required
                        onChange={(e) => setPin(parseInt(e.target.value))}/>
                </div>

                <input type="submit" className="btn btn-primary btn-block mb-4" disabled={loading} value="Sign In"/>

            </Form>
        </div>
    );
}
