import {collectionGroup, getDocs, query, where} from "firebase/firestore";
import {db} from "../../api/firebase";
import {Leave} from "../../api/models";

export async function getLeavesInMonth(month: number, year: number): Promise<Leave[]> {
    // Adjusted start of month
    const monthStart = new Date(year, month, 1);
    // Adjusted end of month
    const monthEnd = new Date(year, month + 1, 0);

    const leavesQuery = query(
        collectionGroup(db, "leaves"),
        where("fromDate", ">=", monthStart),
        where("fromDate", "<=", monthEnd)
    );

    const snapshot = await getDocs(leavesQuery);

    return snapshot.docs.map(doc => {
        const data = doc.data();
        // Convert Firestore Timestamps to JavaScript Date objects
        data.start = data.fromDate.toDate();
        data.end = data.toDate.toDate();
        data.user.name = data.user.name || "Unknown";
        data.title = data.user.name;
        data.id = doc.id;

        return data;
    }) as Leave[];
}
