import {Card, Col} from "react-bootstrap";
import {CardData} from "../../api/models";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faQuestion} from "@fortawesome/free-solid-svg-icons";

export default function StatCard({content}: { content: CardData }) {
    return (
        <Col xs={3} className={"my-2"}>
            <Card>
                <Card.Body>
                    <Card.Subtitle className="mb-2 text-muted d-flex justify-content-between">
                        {content.title}
                        <FontAwesomeIcon icon={faQuestion}/>
                    </Card.Subtitle>
                    <Card.Title>{content.data}</Card.Title>
                </Card.Body>
            </Card>
        </Col>
    );
}
