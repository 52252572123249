import {User} from "firebase/auth";
import Nav from "../../components/Nav";
import {Route, Routes} from "react-router-dom";
import Main from "./main";
import {Edit} from "./edit";

export default function Team({user}: { user: User }) {
    return (
        <Nav user={user}>
            <Routes>
                <Route path="" element={<Main/>}/>
                <Route path=":teamId" element={<Edit/>}/>
            </Routes>
        </Nav>
    );
}
