import "bootstrap/dist/css/bootstrap.min.css";

import React from "react";
import ReactDOM from "react-dom/client";
import {createBrowserRouter, RouterProvider} from "react-router-dom";

import Team from "./pages/team";
import Login from "./pages/login";
import ProtectedRoute from "./components/ProtectedRoute";
import Dashboard from "./pages/dashboard";
import Absence from "./pages/absence";
import Logs from "./pages/logs";

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login/>,
    },
    {
        path: "/dashboard",
        element: <ProtectedRoute Page={Dashboard}/>,
    },
    {
        path: "/absence",
        element: <ProtectedRoute Page={Absence}/>,
    },
    {
        path: "/log",
        element: <ProtectedRoute Page={Logs}/>,
    },
    {
        path: "/team/*",
        element: <ProtectedRoute Page={Team}/>,
    }
]);

const root = ReactDOM.createRoot(
    document.getElementById("root") as HTMLElement
);
root.render(
    <React.StrictMode>
        <RouterProvider router={router}/>
    </React.StrictMode>
);

