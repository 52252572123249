import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "./firebase";

export async function fetchUsers() {
    // Check if the users are cached
    const cacheKey = "users";
    const cachedUsers = localStorage.getItem(cacheKey);
    let lastCached = parseInt(localStorage.getItem("lastCached") || "0");
    const emailsCached = JSON.parse(cachedUsers || "[]");

    // Fetch new users from Firebase
    const usersRef = collection(db, "users");
    const usersQ = query(usersRef, where("createdAt", ">", new Date(lastCached)));
    const users = await getDocs(usersQ);

    const userData = users.docs.map((doc) => doc.get("email"));
    emailsCached.push(...userData);
    emailsCached.sort();

    localStorage.setItem(
        cacheKey,
        JSON.stringify(emailsCached)
    );

    lastCached = Math.max(lastCached, ...users.docs.map((user) => user.get("createdAt").toDate().getTime()));
    localStorage.setItem("lastCached", String(lastCached));

    return emailsCached;
}
