import {Link} from "react-router-dom";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faUser} from "@fortawesome/free-solid-svg-icons";
import {useState} from "react";

export default function TopNav({name}: { name: string }) {
    const [toggle, setToggle] = useState(false);

    return (
        <nav className="navbar navbar-expand-lg d-flex justify-content-end shadow-sm"
            style={{background: "white"}}>
            <div>
                <ul className="navbar-nav d-flex flex-row me-1">
                    <li className="nav-item dropdown">
                        <Link className="nav-link" to="#" onClick={() => setToggle(!toggle)}>
                            {name}
                            <FontAwesomeIcon icon={faUser} className="ms-2"/>
                        </Link>
                        <ul
                            style={{left: "-85px"}}
                            className={`dropdown-menu dropdown-menu-end ${toggle ? "d-block" : ""}`}>
                            <li>
                                <Link className="dropdown-item" to="#">Log out</Link>
                            </li>
                        </ul>
                    </li>
                </ul>
            </div>
        </nav>
    );
}
