import {useNavigate, useParams} from "react-router-dom";
import {useCollectionOnce, useDocumentOnce} from "react-firebase-hooks/firestore";
import {and, collection, doc, query, where, writeBatch} from "firebase/firestore";
import {FormEvent, useEffect, useState} from "react";
import {Alert, Button, Col, Form, Modal, Row, Spinner} from "react-bootstrap";

import {db} from "../../api/firebase";
import {Member} from "../../api/models";
import UserList from "../../components/UserList";

export function Edit() {
    const {teamId} = useParams();
    const [team, tLoading, tError] = useDocumentOnce(doc(db, "teams", teamId || ""));
    const [users, mLoading, mError] = useCollectionOnce(query(collection(db, "users"),
        and(where("team", "==", teamId), where("role", "==", "member"))));

    const [loading, setLoading] = useState(tLoading || mLoading);
    const [error, setError] = useState(tError || mError);

    const [members, setMembers] = useState<Member[]>([]);
    const [leads, setLeads] = useState<Member[]>([]);
    const [name, setName] = useState<string>("");
    const [removed, setRemoved] = useState<string[]>([]);

    const [showDelete, setShowDelete] = useState(false);

    const navigate = useNavigate();

    useEffect(() => {
        if (!team) return;

        setLeads(team.get("leads"));
        setName(team.get("name"));

    }, [team]);

    useEffect(() => {
        if (!users) return;

        setMembers(users.docs.map((doc) => (
            {
                id: doc.id,
                name: doc.get("name") || null,
                email: doc.get("email") || null,
                slackId: doc.get("slackId") || null,
                role: doc.get("role") || null
            }
        )));
    }, [users]);

    useEffect(() => {
        setLoading(tLoading || mLoading);
        setError(tError || mError);
    }, [tLoading, mLoading, tError, mError]);

    async function saveTeam(e: FormEvent<HTMLFormElement>) {
        e.preventDefault();

        setLoading(true);
        setError(undefined);

        console.log(name, leads, members, removed);

        const batch = writeBatch(db);

        const teamRef = doc(db, "teams", teamId || "");
        batch.update(teamRef, {
            name: name,
            leads: leads,
            members: members.length
        });

        for (const member of members) {
            if (users?.docs.find((doc) => doc.id === member.id))
                continue;

            batch.update(doc(db, "users", member.id), {
                team: teamId,
                role: "member"
            });
        }

        for (const lead of leads) {
            if (team?.get("leads").find((l: Member) => l.id === lead.id))
                continue;

            batch.update(doc(db, "users", lead.id), {
                team: teamId,
                role: "lead"
            });
        }

        for (const id of removed) {
            if (
                !users?.docs.find((doc) => doc.id === id) &&
                !leads?.find((lead) => lead.id === id)
            )
                continue;

            batch.update(doc(db, "users", id), {
                team: null,
                role: null
            });
        }

        await batch.commit().catch((e) => setError(e));
        setLoading(false);

        navigate(-1);
    }

    async function deleteTeam()
    {
        setLoading(true);

        const batch = writeBatch(db);

        const teamRef = doc(db, "teams", teamId || "");
        batch.delete(teamRef);

        for (const member of [...members, ...leads]) {
            batch.update(doc(db, "users", member.id), {
                team: null,
                role: null
            });
        }

        await batch.commit().catch((e) => setError(e));
        setLoading(false);

        navigate(-1);
    }

    return (
        <Form className="mt-3 p-3" onSubmit={saveTeam}>
            <Alert variant="danger" hidden={!error}>{error?.message}</Alert>
            <Spinner animation="border" hidden={!loading}/>
            <Row>
                <Col lg="9">
                    <Form.Control type="text" value={name} onChange={(e) => setName(e.target.value)}/>
                </Col>
                <Col lg="3">
                    <Button variant="danger" onClick={() => setShowDelete(true)}>Delete Team</Button>
                </Col>
            </Row>
            <Row className="my-5">
                <Col lg="6" className="px-5">
                    <h5>Members</h5>
                    <UserList users={members} setUsers={setMembers} setRemoved={setRemoved}/>
                </Col>
                <Col lg="6" className="px-5">
                    <h5>Leads</h5>
                    <UserList users={leads} setUsers={setLeads} setRemoved={setRemoved}/>
                </Col>
            </Row>
            <div className="d-flex justify-content-center gap-2">
                <Form.Control type="button" value="Cancel" className="w-25 btn btn-secondary"/>
                <Form.Control type="submit" value="Save" className="w-25 btn btn-primary"/>
            </div>
            <Modal
                show={showDelete}
                onHide={() => setShowDelete(false)}
                backdrop="static"
                keyboard={false}
            >
                <Modal.Header closeButton>
                    <Modal.Title>Delete Team</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    Are you sure you want to delete <b>{name}</b> Team?
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="secondary" onClick={() => setShowDelete(false)}>
                        Close
                    </Button>
                    <Button variant="danger" onClick={deleteTeam}>Delete</Button>
                </Modal.Footer>
            </Modal>
        </Form>
    );
}
