import {User} from "firebase/auth";
import {useAuthState} from "react-firebase-hooks/auth";
import {auth} from "../../api/firebase";
import {Alert, Spinner} from "react-bootstrap";

export default function ProtectedRoute({Page}: { Page: ({user}: { user: User }) => JSX.Element }) {
    const [user, loading, error] = useAuthState(auth);

    if (user)
        return <Page user={user}/>;

    return (
        <>
            <div className="container mt-5">
                <div className="text-center mt-5">
                    <Spinner animation="border" hidden={!loading}/>
                </div>
                <Alert variant="danger" hidden={!error}>{error?.message}</Alert>
            </div>
        </>
    );
}
