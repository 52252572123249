export function toDay(date: Date | string): Date {
    const newDate = typeof date === "string" ? new Date(date) : date;
    newDate.setMinutes(0);
    newDate.setHours(0);
    newDate.setMinutes(0);
    newDate.setSeconds(0);
    newDate.setMilliseconds(0);

    return newDate;
}

export function toTimeString(date: Date | number): string {
    if (typeof date === "object")
        return date.toLocaleTimeString([], { hour: "2-digit", minute: "2-digit" });

    const hours = Math.floor(date / 60 / 60 / 1000);
    const minutes = Math.floor(date / 60 / 1000) % 60;

    return `${hours}:${minutes} Hours`;
}
