import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { getFirestore } from "firebase/firestore";

const firebaseConfig = {
    apiKey: "AIzaSyB9iFpImR8IUHBmlEZS6W8McLBWlGGg9o8",
    authDomain: "monitoring-tool-d3939.firebaseapp.com",
    projectId: "monitoring-tool-d3939",
    storageBucket: "monitoring-tool-d3939.appspot.com",
    messagingSenderId: "828586558290",
    appId: "1:828586558290:web:4fcbb352dd8aa5f4acb08b",
    measurementId: "G-JCZX6L5Y11"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);


// Initialize Firebase Authentication and get a reference to the service
export const auth = getAuth(app);
export const db = getFirestore(app);
