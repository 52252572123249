import {useEffect, useMemo, useState} from "react";
import {User} from "firebase/auth";
import {collectionGroup, orderBy, query, where} from "firebase/firestore";
import {useCollectionData} from "react-firebase-hooks/firestore";
import {Alert, Col, Form, Row, Spinner} from "react-bootstrap";
import TimeLine from "../../components/TimelineChart";
import {db} from "../../api/firebase";
import Nav from "../../components/Nav";
import {fetchUsers} from "../../api/users";
import StatCard from "../../components/StatCard";
import {CardData} from "../../api/models";
import {toDay, toTimeString} from "../../utils/date";

export default function Dashboard({user}: { user: User }) {
    const [date, setDate] = useState(toDay(new Date()));
    const [email, setEmail] = useState("");
    const [emails, setEmails] = useState<string[]>([]);
    const [cards, setCards] = useState<CardData[]>([]);

    const dateRange = useMemo(() => {
        const startOfDay = new Date(date);
        startOfDay.setHours(0, 0, 0, 0);
        const endOfDay = new Date(date);
        endOfDay.setHours(23, 59, 59, 999);

        return [startOfDay, endOfDay];
    }, [date]);

    const historyQuery = useMemo(() => {
        return query(collectionGroup(db, "history"),
            where("timestamp", ">=", dateRange[0]),
            where("timestamp", "<=", dateRange[1]),
            where("email", "==", email),
            orderBy("timestamp"));
    }, [dateRange]);

    const actionQuery = useMemo(() => {
        return query(collectionGroup(db, "actions"),
            where("login", ">=", dateRange[0]),
            where("login", "<=", dateRange[1]),
            orderBy("login"));
    }, [dateRange]);

    const [history, loadingHistory, errorHistory] = useCollectionData(historyQuery);
    const [actions, actionLoading, actionError] = useCollectionData(actionQuery);

    useEffect(() => {
        fetchUsers().then((emails) => setEmails(emails));
    }, []);

    useEffect(() => {
        let deskTime = 0, totalTime = 0;
        const uniqueEmailsLate = new Set();
        const uniqueEmailsOnTime = new Set();

        for (const action of (actions || [])) {
            const loginTime = action.login.toDate();
            const logoutTime = action.logout.toDate();

            totalTime += logoutTime - loginTime;
            deskTime += action.active * 1000;

            const lateHour = 9;
            if (loginTime.getHours() >= lateHour)
                uniqueEmailsLate.add(action.email);
            else
                uniqueEmailsOnTime.add(action.email);
        }

        const late = uniqueEmailsLate.size, onTime = uniqueEmailsOnTime.size;

        setCards([
            {title: "Late", data: String(late)},
            {title: "On Time", data: String(onTime)},
            {title: "Desk Time", data: toTimeString(deskTime)},
            {title: "Total Time", data: toTimeString(totalTime)},
        ]);
    }, [actions]);

    return (
        <Nav user={user}>
            <Row className="my-3">
                <Col sm={6}>
                    <h4>My DeskTime</h4>
                </Col>
                <Col sm={6}>
                    <Form.Control type="date" value={date.toISOString().slice(0, 10)}
                        onChange={(e) => setDate(toDay(e.target.value))}/>
                </Col>
            </Row>
            <Row className="flex-wrap m-2">
                {actionError && <Alert variant="danger">Error loading stats</Alert>}
                {actionLoading && <Spinner animation="border"/>}
                {cards.map((card, i) => (<StatCard key={i} content={card}/>))}
            </Row>
            <Row className="mt-3">
                <Row>
                    <Col sm={6}>
                        <h5>Timeline</h5>
                    </Col>
                    <Col sm={6}>
                        <Form.Select onChange={(e) => setEmail(e.target.value)}>
                            {emails.map((email) => (
                                <option key={email} value={email}>{email}</option>
                            ))}
                        </Form.Select>
                    </Col>
                </Row>
                <Col sm={12}>
                    {errorHistory && <Alert variant="danger">Error loading timeline</Alert>}
                    {loadingHistory && <Spinner animation="border"/>}
                    {<TimeLine data={history || []} start={6} end={21}/>}
                </Col>
            </Row>
        </Nav>
    );
}
