import SideNav from "../SideNav";
import {Col, Row} from "react-bootstrap";
import TopNav from "../TopNav";
import {ReactNode} from "react";
import {User} from "firebase/auth";

export default function Nav({children, user}: { children: ReactNode[] | ReactNode, user: User }) {
    const path = window.location.pathname;

    return (
        <Row className="h-100">
            <SideNav active={path}/>
            <Col style={{background: "#EAEAEA"}} className="ps-0">
                <TopNav name={user.displayName || "You"}/>
                <Row className="ps-2">
                    {children}
                </Row>
            </Col>
        </Row>
    );
}
