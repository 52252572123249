import "./SideNav.css";

import {Col, Nav} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faCalendar, faDesktop, faUsers, faNotesMedical} from "@fortawesome/free-solid-svg-icons";

export default function SideNav({active}: {active: string}) {
    const navLinks = [
        {route: "/dashboard", name: "Dashboard", icon: faDesktop},
        {route: "/team", name: "Team Members", icon: faUsers},
        // {route: "/schedule", name: "Work Schedule", icon: faBriefcase},
        {route: "/absence", name: "Absence Calendar", icon: faCalendar},
        {route: "/log", name: "Logs", icon: faNotesMedical},
        // {route: "/offline", name: "Offline Times", icon: faClock},
    ];

    return (
        <Col xs={2} style={{background: "#1C2932"}} className="pt-5">
            <Nav defaultActiveKey={active} className="flex-column mt-5">
                {navLinks.map((link, i) => (
                    <Nav.Link
                        key={i}
                        href={link.route}
                        className={`side-nav-link ${active === link.route ? "active-side-nav-link" : ""}`}>
                        <FontAwesomeIcon size="xl" icon={link.icon}
                            style={{color: "#ffffff"}} className="me-3" />
                        {link.name}
                    </Nav.Link>
                ))}
            </Nav>
        </Col>
    );
}
