import {DocumentData} from "firebase/firestore";
import {useMemo} from "react";
import {Container} from "react-bootstrap";

export default function TimeLine({data, start, end}: {
    data: DocumentData[],
    start: number,
    end: number
}) {

    const segments = useMemo(() => {
        // Define the start and end of the timeline in total minutes
        const startOfDay = start * 60;
        const endOfDay = end * 60;

        const timestamps = data.map(item => {
            const date = item.timestamp.toDate();
            const totalMinutes = date.getHours() * 60 + date.getMinutes();
            return {
                action: item.action,
                percentage: ((totalMinutes - startOfDay) / (endOfDay - startOfDay)) * 100,
            };
        });

        const segments = [];
        let lastPercentage = 0;
        let lastAction = "logout";

        // Create segments based on the timestamps
        for (let i = 0; i < timestamps.length; i++) {
            const {percentage, action} = timestamps[i];
            segments.push({
                start: lastPercentage,
                end: percentage,
                color: lastAction === "login" ? "#45a9ff" : "lightgray",
            });
            lastPercentage = percentage;
            lastAction = action;
        }

        // Add a final segment to reach the end of the day if needed
        if (lastPercentage < 100) {
            segments.push({
                start: lastPercentage,
                end: 100,
                color: lastAction === "login" ? "#45a9ff" : "lightgray",
            });
        }

        return segments;
    }, [data]);

    const timeLabels = useMemo(() => {
        const labels = [];
        for (let i = start; i <= end; i++) {
            labels.push(((i % 12) || 12) + (i >= 12 ? "pm" : "am"));
            labels.push("`");
        }
        labels.pop();

        return labels;
    }, [start, end]);

    return (
        <Container className="bg-white card mt-3 p-3">
            <div className="d-flex flex-row w-100">
                {segments.map((segment, index) => (
                    <div className="rounded"
                        key={index}
                        style={{
                            flexBasis: `${segment.end - segment.start}%`,
                            backgroundColor: segment.color,
                            height: "1rem",
                        }}
                    />
                ))}
            </div>
            <div className="d-flex flex-row w-100 justify-content-between">
                {timeLabels.map((label, index) => (
                    <span key={index} style={{ fontSize: "12px" }}>
                        {label}
                    </span>
                ))}
            </div>
        </Container>
    );
}
