import {useCollection} from "react-firebase-hooks/firestore";
import {addDoc, collection} from "firebase/firestore";

import {Link, useNavigate} from "react-router-dom";
import {Alert, Spinner} from "react-bootstrap";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";
import {faPlusCircle} from "@fortawesome/free-solid-svg-icons";

import {db} from "../../api/firebase";
import {Member} from "../../api/models";

export default function Main() {
    let [teams, loading, error] = useCollection(collection(db, "teams"));

    const navigate = useNavigate();

    function createTeam() {
        loading = true;
        addDoc(collection(db, "teams"), {
            name: "New Team",
            leads: [],
            members: 0
        })
            .then((docRef) => navigate(docRef.id))
            .then(() => teams = undefined)
            .catch((e) => error = e);
    }

    if (error)
        return <Alert variant="danger">{error.message}</Alert>;

    return (
        <div className="d-flex flex-wrap p-3 gap-2">
            <div className="card d-flex justify-content-center align-content-center flex-wrap" style={{width: "18rem"}}>
                <FontAwesomeIcon size="4x" icon={faPlusCircle} onClick={createTeam}/>
            </div>
            {loading &&
                <div className="card d-flex text-center align-content-center" style={{width: "18rem"}}>
                    <Spinner animation="border"/>
                </div>}

            {teams && teams.docs.map((team, i) => (
                <div className="card" style={{width: "18rem"}} key={i}>
                    <div className="card-body">
                        <h5 className="card-title">{team.get("name")}</h5>
                        <h6 className="card-subtitle mb-2 text-muted">Team</h6>
                        <div className="card-text">
                            <p>
                                <b>Lead</b>:
                                {team.get("leads").map((lead: Member) => lead.name).join(",")}
                                <br/>
                                <b>Members</b>: {team.get("members")}
                            </p>
                        </div>
                        <Link to={team.id}>View</Link>
                    </div>
                </div>
            ))}
        </div>
    );
}
