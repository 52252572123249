import "react-big-calendar/lib/css/react-big-calendar.css";

import {useEffect, useState} from "react";

import {User} from "firebase/auth";
import {Button, Container, Modal, Table} from "react-bootstrap";
import {Calendar, momentLocalizer} from "react-big-calendar";
import moment from "moment";

import Nav from "../../components/Nav";
import {Leave} from "../../api/models";
import {getLeavesInMonth} from "./data";
import {TitleCase} from "../../utils/validation";

const localizer = momentLocalizer(moment);

export default function Absence({user}: { user: User }) {
    const [currentMonth, setCurrentMonth] = useState(new Date());
    const [events, setEvents] = useState<Leave[]>([]);
    const [modalInfo, setModalInfo] = useState<Leave | null>(null);

    useEffect(() => {
        const fetchLeaves = async () => {
            const fetchedLeaves = await getLeavesInMonth(currentMonth.getMonth(), currentMonth.getFullYear());
            setEvents(fetchedLeaves);
        };

        fetchLeaves().then();
    }, [currentMonth]);

    return (
        <Nav user={user}>
            <Container>
                <Calendar
                    localizer={localizer}
                    defaultDate={new Date()}
                    defaultView="month"
                    events={events}
                    style={{height: "80vh"}}
                    onSelectEvent={(event) => setModalInfo(event)}
                    onNavigate={(newDate) => setCurrentMonth(newDate)}
                />

                <Modal show={modalInfo !== null} onHide={() => setModalInfo(null)}>
                    <Modal.Header closeButton>
                        <Modal.Title>{modalInfo?.title}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body>
                        <Table bordered hover size="sm">
                            <tbody>
                                <tr>
                                    <th>Duration</th>
                                    <td>{modalInfo?.start?.toLocaleDateString()} to {modalInfo?.end?.toLocaleDateString()}</td>
                                </tr>
                                <tr>
                                    <th>Type</th>
                                    <td>{modalInfo?.type}</td>
                                </tr>
                                <tr>
                                    <th>Day Type</th>
                                    <td>{modalInfo?.isHalfDay ? "Half Day" : "Full Day"}</td>
                                </tr>
                                <tr>
                                    <th>Status</th>
                                    <td>{TitleCase(modalInfo?.status || "")}</td>
                                </tr>
                                <tr>
                                    <th>Reason</th>
                                    <td>{modalInfo?.reason}</td>
                                </tr>
                            </tbody>
                        </Table>
                    </Modal.Body>
                    <Modal.Footer>
                        <Button variant="secondary" onClick={() => setModalInfo(null)}>Close</Button>
                    </Modal.Footer>
                </Modal>
            </Container>
        </Nav>
    );
}
