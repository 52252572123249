import {Member} from "../../api/models";
import {Button, Card, Col, Container, Form, FormText, InputGroup} from "react-bootstrap";
import {Dispatch, SetStateAction, useState} from "react";
import {checkValidEmail} from "../../utils/validation";
import {collection, getDocs, limit, query, where} from "firebase/firestore";
import {db} from "../../api/firebase";
import {faTrashCan} from "@fortawesome/free-solid-svg-icons";
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome";

interface UserListProps {
    users: Member[];
    setUsers: Dispatch<SetStateAction<Member[]>>;
    setRemoved: Dispatch<SetStateAction<string[]>>;
}

export default function UserList({users, setUsers, setRemoved}: UserListProps) {
    const [email, setEmail] = useState("");
    const [invalid, setInvalid] = useState(false);
    const [loading, setLoading] = useState(false);
    const [invalidUsers, setInvalidUsers] = useState<string[]>([]);

    async function addUser() {
        if (!checkValidEmail(email))
            return setInvalid(true);

        setLoading(true);

        const userQuery = query(collection(db, "users"),
            where("email", "==", email),
            limit(1)
        );

        const user = (await getDocs(userQuery)).docs[0];

        if (user?.exists())
            setUsers([...users, {
                id: user.id,
                name: user.get("name") || null,
                email: email,
                slackId: user.get("slackId") || null,
                role: user.get("role") || null
            }]);
        else
            setInvalidUsers([...invalidUsers, email]);

        setLoading(false);
    }

    function remove(email: string | null) {
        if (!email)
            return;

        const removed = users.find((u) => u.email === email)?.id;

        if (removed) {
            setRemoved((r) => [...r, removed]);
            setUsers(users.filter((u) => u.email !== email));
        }
        else
            setInvalidUsers(invalidUsers.filter((e) => e !== email));
    }

    return (
        <div>
            <Container className="my-3">
                {users.map((user, i) => (
                    <Card key={i}>
                        <Card.Body>
                            <Card.Text className="d-flex justify-content-between">
                                <span>{user.name}</span>
                                <FontAwesomeIcon size="xl" icon={faTrashCan} color="red"
                                    onClick={() => remove(user.email)}/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
                {invalidUsers.map((email, i) => (
                    <Card key={i}>
                        <Card.Body>
                            <Card.Text className="d-flex justify-content-between">
                                <span className="text-danger">{email}</span>
                                <FontAwesomeIcon size="xl" icon={faTrashCan} color="red" onClick={() => remove(email)}/>
                            </Card.Text>
                        </Card.Body>
                    </Card>
                ))}
            </Container>
            <Form.Group as={Col} md="12" controlId="validationCustomUsername">
                <InputGroup hasValidation>
                    <Form.Control
                        type="email"
                        placeholder="Email"
                        value={email}
                        onChange={(e) => setEmail(e.target.value.toLocaleLowerCase())}
                        onKeyDown={() => setInvalid(!checkValidEmail(email))}
                    />
                    <Button onClick={addUser} disabled={loading}>Add</Button>
                </InputGroup>
                <FormText hidden={!invalid} className="text-danger ps-2">
                    Please enter a valid email.
                </FormText>
            </Form.Group>

        </div>
    );
}
