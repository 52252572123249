import "./logs.css";

import {User} from "firebase/auth";
import {Container, Form, Row} from "react-bootstrap";
import Nav from "../../components/Nav";
import {collection, collectionGroup, getDocs, query, where} from "firebase/firestore";
import {db} from "../../api/firebase";
import {ChangeEvent, useEffect, useState} from "react";
import {Log} from "../../api/models";

import {toDay} from "../../utils/date";

export default function Logs({user}: { user: User }) {
    const [emails, setEmails] = useState<string[]>([]);
    const [logs, setLogs] = useState<Log[]>([]);
    const [selectedEmail, setSelectedEmail] = useState("");
    const [selectedDate, setSelectedDate] = useState(new Date());

    useEffect(() => {
        async function fetchUsers() {
            // Check if the users are cached
            const cacheKey = "users";
            const cachedUsers = localStorage.getItem(cacheKey);
            let lastCached = parseInt(localStorage.getItem("lastCached") || "0");
            const emailsCached = JSON.parse(cachedUsers || "[]");

            if (cachedUsers && emailsCached?.length)
                setEmails(emailsCached);

            // Fetch new users from Firebase
            const usersRef = collection(db, "users");
            const usersQ = query(usersRef, where("createdAt", ">", new Date(lastCached)));
            const users = await getDocs(usersQ);

            const userData = users.docs.map((doc) => doc.get("email"));
            emailsCached.push(...userData);

            setEmails(emailsCached);
            localStorage.setItem(
                cacheKey,
                JSON.stringify(emailsCached)
            );

            lastCached = Math.max(lastCached, ...users.docs.map((user) => user.get("createdAt").toDate().getTime()));
            localStorage.setItem("lastCached", String(lastCached));
        }

        fetchUsers().then();
    }, []);

    useEffect(() => {
        async function fetchLogs() {
            // Check if the logs are cached
            const cacheKey = `logs-${selectedDate.toISOString().substring(0, 10)}-${selectedEmail}`;
            const cachedLogs = localStorage.getItem(cacheKey);

            const today = toDay(new Date());

            if (cachedLogs)
                setLogs(JSON.parse(cachedLogs));

            if (selectedDate.getTime() !== today.getTime() && cachedLogs)
                return;

            // Logs are not cached, fetch them from Firebase
            const logsRef = collectionGroup(db, "logs");
            const logsQ = query(logsRef,
                where("email", "==", selectedEmail),
                where("time", ">=", selectedDate),
                where("time", "<", new Date(selectedDate.getTime() + 24 * 60 * 60 * 1000)));

            const logs = await getDocs(logsQ);
            const logsData = logs.docs.map((doc) => ({
                screenshot: doc.get("screenshot"),
                window: doc.get("window"),
                time: doc.get("time").toDate().getTime(),
            }));

            // Cache the logs
            localStorage.setItem(cacheKey, JSON.stringify(logsData));
            setLogs(logsData);

        }

        fetchLogs().then();
    }, [selectedEmail, selectedDate]);

    function handleDate(e: ChangeEvent<HTMLInputElement>)
    {
        setSelectedDate(toDay(e.target.value));
    }

    return (
        <Nav user={user}>
            <Container>
                <Row>
                    <Form className="form-inline row my-2">
                        <Form.Group className="col-6 inline">
                            <Form.Label>Email</Form.Label>
                            <Form.Select onChange={(e) => setSelectedEmail(e.target.value)}>
                                {emails.map((email) => (
                                    <option key={email} value={email}>{email}</option>
                                ))}
                            </Form.Select>
                        </Form.Group>
                        <Form.Group className="col-6">
                            <Form.Label>Date</Form.Label>
                            <Form.Control type="date" onChange={handleDate}/>
                        </Form.Group>
                    </Form>
                </Row>
                <div className="logsContainer" >
                    {logs.map((log) => (
                        <div className="screenshot" key={log.screenshot}>
                            <a href={log.screenshot}>
                                <img src={log.screenshot} alt={log.window}/>
                            </a>
                            <div className="info">
                                {log.window} - {new Date(log.time).toLocaleTimeString([], {
                                    hour: "2-digit",
                                    minute: "2-digit",
                                })}
                            </div>
                        </div>
                    ))}
                </div>
            </Container>
        </Nav>
    );
}
